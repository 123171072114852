<template>
  <div class="content">
    <PageHeader :title="'Novo template'" />
    <section class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-md-3 col-lg-3 col-sm-12">
                  <div class="input-group">
                    <div class="">

                    </div>
                  </div>
                </div>
                <div class="col-lg-9 col-md-9">
                  <div class="input-group" style="flex-direction: row-reverse;">
                    <div class="input-group-prepend">
                      <button
                        class="btn btn-default" @click="$router.go(-1)"
                      >
                        <i class="fas fa-angle-left"></i> Voltar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body block-el p-0" style="background: #fafafa; /* #f0f6ff */">
              <div class="row">
                <div class="col-sm-3"></div>
                <div class="col-sm-6">
                  <div class="new-template-whats">
                    <section>
                      <h3 class="new-template-title">
                        Categoria
                      </h3>
                      <p>
                        <span>
                          Escolha um modelo de mensagem.
                        </span>
                      </p>
                    </section>
                    <section>
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item" @click="changeCategory('TRANSACTIONAL')" :class="{ 'list-group-item-active': this.template.category === 'TRANSACTIONAL' }">
                          <div class="media">
                            <div class="align-self-center mr-3 w-40 rounded-circle">
                              <i class="fas fa-briefcase" style="font-size: 35px; color: #000;"></i>
                            </div>
                            <div class="media-body">
                              <p class="mb-0"><strong class="">Transacional</strong></p>
                              <span>
                                Envie atualizações da conta, atualizações de pedido, alertas e muito mais para compartilhar informações importantes.
                              </span>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item" @click="changeCategory('MARKETING')" :class="{ 'list-group-item-active': this.template.category === 'MARKETING' }">
                          <div class="media">
                            <div class="align-self-center mr-3 w-40 rounded-circle">
                              <i class="fas fa-bullhorn" style="font-size: 35px; color: #000;"></i>
                            </div>
                            <div class="media-body">
                              <p class="mb-0"><strong class="">Marketing</strong></p>
                              <span>
                                Envie ofertas promocionais, anúncios de produtos e muito mais para aumentar o reconhecimento e o engajamento.
                              </span>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item" @click="changeCategory('DISPOSABLE_PASSWORDS')" :class="{ 'list-group-item-active': this.template.category === 'DISPOSABLE_PASSWORDS' }">
                          <div class="media">
                            <div class="align-self-center mr-3 w-40 rounded-circle">
                              <i class="fas fa-key" style="font-size: 35px; color: #000;"></i>
                            </div>
                            <div class="media-body">
                              <p class="mb-0"><strong class="">Senhas descartáveis</strong></p>
                              <span>
                                Envie códigos para permitir que seus clientes acessem as contas deles.
                              </span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </section>
                  </div>
                  <div class="new-template-whats">
                    <section>
                      <h3 class="new-template-title">
                        Nome
                      </h3>
                      <p>
                        <span>
                          Dê um nome para o seu modelo de mensagem.
                        </span>
                      </p>
                    </section>
                    <section class="relative">
                      <input class="form-control" v-model="template.name" maxlength="510"/>
                      <span class="input-info">{{template.name.length}}/512</span>
                    </section>
                  </div>
                  <div class="new-template-whats">
                    <section>
                      <h3 class="new-template-title">
                        <span style="float:left;">Header</span><span class="optional-template-input">Opcional</span>
                      </h3>
                      <div style="clear: both;"></div>
                      <p style="display: block; margin-top: 8px;">
                        <span>
                          Adicione um título ou escolha o tipo de mídia que você usará para este cabeçalho.
                        </span>
                      </p>
                    </section>
                    <section class="relative">
                      <div class="row">
                        <div class="col-sm-4">
                          <select class="form-control" style="margin-bottom: 15px; width: 100%;" v-model="template.header_type" @input="changeHeaderType">
                            <option value="" selected>
                              Nenhum
                            </option>
                            <option value="TEXT">
                              Texto
                            </option>
                            <option value="MIDIA">
                              Mídia
                            </option>
                          </select>
                        </div>
                        <div class="col-sm-4" v-if="template.header_type == 'MIDIA'">
                          <button v-if="template.image_type == 'image'" class="btn btn-success btn-sm" @click="
                            callCrop(
                              'img-preview',
                              500,
                              280,
                              cropActive,
                            )">
                            Amostra
                          </button>
                          <div class="form-group row" v-if="template.image_type == 'video'">
                            <div class="col-md-12">
                              <input
                                ref="uploadedMp4"
                                type="file"
                                accept=".mp4"
                                hidden
                                @change="uploadFileVideo"
                              />
                              <button class="btn btn-success btn-sm" @click="openFileUpload">
                                Amostra
                              </button>
                            </div>
                          </div>
                          <div class="form-group row" v-if="template.image_type == 'document'">
                            <div class="col-md-12">
                              <input
                                ref="uploadedMp4"
                                type="file"
                                accept=".pdf"
                                hidden
                                @change="uploadFilePdf"
                              />
                              <button class="btn btn-success btn-sm" @click="openFileUpload">
                                Amostra
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="template.header.type == 'TEXT'">
                        <span>Texto do header</span>
                        <div class="relative">
                          <input class="form-control" v-model="template.header.text" maxlength="60"/>
                          <span class="input-info">{{template.header.text.length}}/60</span>
                        </div>
                      </div>
                      <div class="m-t-10" v-if="template.header.type == 'IMAGE' || template.header.type == 'VIDEO' || template.header.type == 'DOCUMENT'">
                        <div class="form-group row">
                          <div class="col-md-4 text-center">
                            <div class="preview-media-select preview-media-select-image" :class="{'preview-media-select-active': template.image_type == 'image'}" @click="template.header.type = 'IMAGE', template.image_type = 'image', defaultBg = '/assets/img/image_preview_white.png', hidePreview = false">
                              <label></label>
                              <span>Imagem</span>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="preview-media-select preview-media-select-video" :class="{'preview-media-select-active': template.image_type == 'video'}" @click="template.header.type = 'VIDEO', template.image_type = 'video', defaultBg = '/assets/img/video_preview_white.png', hidePreview = false">
                              <label></label>
                              <span>Vídeo</span>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="preview-media-select preview-media-select-document" :class="{'preview-media-select-active': template.image_type == 'document'}" @click="template.header.type = 'DOCUMENT', template.image_type = 'document', defaultBg = '/assets/img/document_preview_white.png', hidePreview = false">
                              <label></label>
                              <span>Documento</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                  <div class="new-template-whats">
                    <section>
                      <h3 class="new-template-title">
                        Body
                      </h3>
                      <p>
                        <span>
                          Digite o texto da sua mensagem.
                        </span>
                      </p>
                    </section>
                    <section class="relative">
                      <whats-input
                        v-model="template.body"
                        :variables="vars"
                        :paramsChange="paramsChange"
                      />
                      <span class="input-info" style="top: 35px;">{{template.body.length}}/1024</span>

                      <div class="" v-if="this.template.body_examples" style="margin-top:10px;">
                        <div class="alert alert-warning" v-if="this.template.body_examples.length > 0">
                          Parâmetros de exemplo
                        </div>
                        <div class="form-group">
                          <div class="row" v-for="(p) in template.body_examples" :key="p.index">
                            <div class="col">
                              <div class="relative">
                                <label>Parâmetro {{p.index}}</label>
                                <input class="form-control" type="text" v-model="p.text"/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                  <div class="new-template-whats">
                    <section>
                      <h3 class="new-template-title">
                        <span style="float:left;">Footer</span><span class="optional-template-input">Opcional</span>
                      </h3>
                      <div style="clear: both;"></div>
                      <p style="display: block; margin-top: 8px;">
                        <span>
                          Adicione uma pequena linha de texto na parte inferior do seu modelo de mensagem.
                        </span>
                      </p>
                    </section>
                    <section class="relative">
                      <input class="form-control" v-model="template.footer" maxlength="60"/>
                      <span class="input-info">{{template.footer.length}}/60</span>
                    </section>
                  </div>
                  <div class="new-template-whats">
                    <section>
                      <h3 class="new-template-title">
                        <span style="float:left;">Buttons</span><span class="optional-template-input">Opcional</span>
                      </h3>
                      <div style="clear: both;"></div>
                      <p style="display: block; margin-top: 8px;">
                        <span>
                          Crie até 3 botões que permitem que os clientes respondam à sua mensagem ou atuem.
                        </span>
                      </p>
                    </section>
                    <section class="relative">
                      <select class="form-control" style="margin-bottom: 15px; width: 40%;" v-model="template.button_type" @change="changeTypeButton">
                        <option value="">
                          Nenhum
                        </option>
                        <option value="quick_reply">
                          Resposta rápida
                        </option>
                        <option value="call_to_action">
                          Call to Action
                        </option>
                      </select>
                      <div v-if="template.button_type === 'quick_reply'">
                        <div v-for="(button, i) in template.buttons_reply" :key="i" class="m-t-10">
                          <span>Texto do botão</span>
                          <div class="relative" style="width: 70%;">
                            <input class="form-control" v-model="button.text" maxlength="25"/>
                            <span class="input-info">{{button.text.length}}/25</span>
                            <span style="float: right; top: 6px; position: absolute; right:-20px;" class="c-pointer" @click="removeReply(i)" v-if="template.buttons_reply.length > 1"><i class="fas fa-times"></i></span>
                          </div>
                        </div>
                      </div>
                      <div class="m-t-10" v-if="template.button_type === 'call_to_action'">
                        <div class="form-group row rw-buttons-preview" v-for="(button, i) in template.call_to_actions" :key="i">
                          <div class="col-md-4" v-if="button.type == 'PHONE_NUMBER' && template.call_to_actions.length > 1">
                            <select type="text" class="form-control" v-model="button.type">
                              <option value="PHONE_NUMBER">Telefone</option>
                            </select>
                          </div>
                           <div class="col-md-4" v-if="button.type == 'URL' && template.call_to_actions.length > 1">
                            <select type="text" class="form-control" v-model="button.type">
                              <option value="URL">Url</option>
                            </select>
                          </div>
                          <div class="col-md-4" v-if="template.call_to_actions.length < 2">
                            <select
                              v-model="button.type"
                              type="text"
                              class="form-control">
                              <option value="PHONE_NUMBER">{{$tc('generic-str.tel', 1)}}</option>
                              <option value="URL">Url</option>
                            </select>
                          </div>
                          <div class="col-md-4">
                            <input type="text" maxlength="20" v-model="button.text" placeholder="Texto" class="form-control">
                          </div>
                          <div class="col-md-4" v-if="button.type == 'URL'">
                            <input
                              v-model="button.url"
                              type="text"
                              class="form-control"
                              placeholder="URL"
                              maxlength="1990"
                            />
                          </div>
                          <div class="col-md-4" v-if="button.type == 'PHONE_NUMBER'">
                            <input
                              type="tel"
                              class="form-control"
                              v-mask="'+55 (##) #####-####'"
                              placeholder="+55 (00) 00000-0000"
                              maxlength="20"
                              v-model="button.phone_number"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="m-t-10" v-if="template.button_type === 'quick_reply'">
                        <button class="btn btn-info" @click="addQuickReply" :class="{'disabled' : template.buttons_reply.length == 3}"><i class="fas fa-plus"></i></button>
                      </div>
                      <div class="m-t-10" v-if="template.button_type === 'call_to_action'">
                        <button class="btn btn-info" @click="addCallToButton" :class="{'disabled' : template.call_to_actions.length == 2}"><i class="fas fa-plus"></i></button>
                      </div>
                    </section>
                  </div>
                  <div>
                    <button @click="saveTemplate" class="btn btn-success" style="margin-left: 10px; margin-bottom: 10px;"
                      :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }"
                      :disabled="isSending">Salvar
                    </button>
                  </div>
                </div>
                <div class="col-sm-3">
                  <section class="template-preview-new">
                    <h3>Preview</h3>
                    <div class="preview-messages">
                      <div class="preview-messages-container">
                        <video style="width: 100%;" v-if="template.image_type == 'video'" ref="previewMp4" :class="{'hide' : !hidePreview}">
                          <source>
                        </video>
                        <iframe src="" id="preview-image-header-pdf" ref="previewPdf" v-if="template.image_type == 'document'" :class="{'hide' : !hidePreview}"></iframe>
                        <div class="preview-image-header" id="preview-image-header" :style="`background-image: url('${defaultBg}');`" v-if="template.header_type == 'MIDIA' && !hidePreview">
                        </div>
                        <div class="preview-message-header">
                          {{template.header.text}}
                        </div>
                        <div class="preview-message-body">
                          <span>{{template.body}}</span>
                        </div>
                        <div class="preview-message-footer">
                          {{template.footer}}
                        </div>
                        <time class="time-messages">17:50</time>
                        <div class="preview-message-btns" v-if="template.button_type === 'call_to_action'">
                          <span v-for="(button, i) in template.call_to_actions" :key="i">
                            <i class="fas fa-phone" v-if="button.type == 'PHONE_NUMBER'"></i>
                            <i class="fas fa-edit" v-if="button.type == 'URL'"></i>
                            {{button.text}}
                          </span>
                        </div>
                      </div>
                      <div class="preview-action-buttons" v-if="template.button_type === 'quick_reply'">
                        <div v-for="(button, i) in template.buttons_reply" :key="i" class="div-reply">
                          {{button.text}}
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <create-template-modal id="create-template-modal"/>
    <send-template-modal id="send-template-modal"/>
    <preview-modal id="preview-modal"/>
    <m-crop id="m-crop" />
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import CreateTemplateModal from '@/components/whatsapp/CreateTemplateModal.vue';
import SendTemplateModal from '@/components/whatsapp/SendTemplateModal.vue';
import PreviewModal from '@/components/whatsapp/PreviewModal.vue';
import WhatsInput from '@/components/whatsapp/WhatsInput.vue';
import WhatsAppService from '@/services/whatsapp.service';
import MCrop from '@/components/MCrop.vue';
import BucketService from '@/services/bucket.service';
import axios from '@/plugins/axios';

export default {
  name: 'WhatsAppTemplates',
  components: {
    PageHeader,
    CreateTemplateModal,
    PreviewModal,
    SendTemplateModal,
    WhatsInput,
    MCrop,
  },
  data() {
    return {
      uploadProgress: 0,
      form: {},
      isSending: false,
      fetched: false,
      client: {},
      defaultBg: '/assets/img/image_preview_white.png',
      defaultBgPdf: '/assets/img/image_preview_white.png',
      cropActive: null,
      cropPreview: null,
      hidePreview: false,
      vars: [
        { name: this.$t('generic-str.lbl-first-name'), value: 'first_name' },
        { name: this.$t('generic-str.lbl-last-name'), value: 'last_name' },
        { name: this.$t('generic-str.lbl-cellphone'), value: 'mobile_number' },
        { name: 'Email', value: 'email' },
      ],
      file: null,
      template: {
        image_type: 'image',
        header_type: '',
        name: '',
        buttons_reply: [],
        call_to_actions: [],
        button_type: '',
        category: '',
        body: '',
        footer: '',
        body_examples: [],
        url: null,
        header: {
          type: '',
          text: '',
        },
        language: 'pt_BR',
      },
    };
  },
  created() {
    this.$root.$on('call.crop', this.crop);
  },
  methods: {
    changeHeaderType(ev) {
      const type = ev.target.value;
      switch (type) {
        case 'TEXT':
          this.template.header.type = 'TEXT';
          break;
        case 'MIDIA':
          this.template.header.type = 'IMAGE';
          break;
        default:
          break;
      }
    },
    openFileUpload() {
      this.$refs.uploadedMp4.click();
    },
    uploadFileVideo(event) {
      this.hidePreview = true;
      const file = event.target.files[0];
      this.$refs.previewMp4.src = URL.createObjectURL(file);
      this.$refs.previewMp4.play();
      this.file = file;
    },
    uploadFilePdf(event) {
      this.hidePreview = true;
      const file = event.target.files[0];
      const reader = new FileReader();
      // const preview = this.$refs.previewPdf;
      const preview = document.getElementById('preview-image-header-pdf');
      console.log(preview);
      reader.addEventListener('load', () => {
        preview.src = reader.result;
        this.defaultBgPdf = reader.result;
      }, false);

      preview.addEventListener('load', (ev) => {
        console.log(ev.target.contentDocument);
        const style = document.createElement('style');
        style.textContent = '#viewerContainer { top: 0px !important; }';
        ev.target.contentDocument.head.appendChild(style);
      });

      if (file) {
        reader.readAsDataURL(file);
      }
      this.file = file;
    },
    uploadVideo() {
      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('visibility', 'public');
      formData.append('account_id', this.$live.contextApp.$store.state.account.id);
      console.log(this.file);
      Promise.all([
        BucketService.uploadFile(formData, true, this.callBackProgress),
      ])
        .then(
          (responses) => {
            console.log(responses);
            console.log(responses[0].data.url);
            this.template.url = responses[0].data.url;
            this.sendRequest();
          },
          (error) => {
            this.content = error;
            this.isSending = false;
            console.log(error);
          },
        )
        .finally(() => {
          this.isSending = false;
        });
    },
    uploadFile() {
      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('visibility', 'public');
      formData.append('account_id', this.$live.contextApp.$store.state.account.id);
      console.log(this.file);
      Promise.all([
        BucketService.uploadFile(formData, true, this.callBackProgress),
      ])
        .then(
          (responses) => {
            console.log(responses);
            console.log(responses[0].data.url);
            this.template.url = responses[0].data.url;
            this.sendRequest();
          },
          (error) => {
            this.content = error;
            this.isSending = false;
            console.log(error);
          },
        )
        .finally(() => {
          this.isSending = false;
        });
    },
    callCrop(
      cropPreview,
      maxWidthP,
      maxHeightP,
      cropActive,
    ) {
      this.cropPreview = cropPreview;
      this.cropActive = cropActive;
      this.$root.$emit('show.modal', 'm-crop');
      this.$store.dispatch('crop/config', {
        maxWidth: maxWidthP,
        maxHeight: maxHeightP,
        viewMode: 1,
        aspectRatio: 2,
        minSize: true,
        infoSize: '1700x800',
      });
    },
    crop(data) {
      this.cropActive = data;
      this.$root.$emit('show.modal', 'request-modal');
      this.file = data;
      console.log('this.file', this.file);
      document.getElementById('preview-image-header').style = `background: url(${this.file.url}); background-size: cover;`;
    },
    uploadPicture() {
      const formData = new FormData();
      const filesCrop = [];
      filesCrop.push({
        x: this.file.x,
        y: this.file.y,
        w: this.file.width,
        h: this.file.height,
      });
      formData.append('files[]', this.file.file);
      formData.append('role', 'public');
      formData.append('folder', 'whatsapp');
      formData.append('crop', JSON.stringify(filesCrop));

      Promise.all([
        BucketService.upload(
          formData,
          true,
          this.callBackProgress,
        ),
      ])
        .then(
          (responses) => {
            console.log(responses);
            console.log(`${process.env.VUE_APP_API_BUCKET}${responses[0].data[0].url}`);
            this.template.url = `${responses[0].data[0].url}`;
            this.sendRequest();
          },
          (error) => {
            this.content = error;
            this.isSending = false;
            console.log(error);
          },
        )
        .finally(() => {
          this.isSending = false;
        });
    },
    changeTypeButton(event) {
      this.template.buttons_reply = [];
      this.template.call_to_actions = [];
      console.log(event.originalTarget.value);
      if (event.originalTarget.value === 'quick_reply') {
        this.template.buttons_reply.push({ type: 'QUICK_REPLY', text: 'Teste' });
      }
      if (event.originalTarget.value === 'call_to_action') {
        this.template.call_to_actions.push({ type: 'PHONE_NUMBER', text: 'Telefone', phone_number: '(00) 00000-000000' });
      }
    },
    callBackProgress(progress) {
      this.uploadProgress = progress;
    },
    saveTemplate() {
      let errorMessage = null;
      if (this.template.body_examples.length > 0) {
        this.template.body_examples.forEach((param) => {
          if (param.text === undefined || param.text === '' || param.text === null) {
            errorMessage = this.$t('template-component.params');
          }
        });
      }
      if (errorMessage) {
        this.$toast.show({
          title: this.$t('sms.infos.filters.status.lbl-error'),
          content: errorMessage,
          type: 'danger',
        });
      }

      if (errorMessage == null) {
        this.isSending = true;
        console.log('Template to save');
        console.log(this.template);
        if (this.template.header.type === 'VIDEO') {
          this.uploadVideo();
        }

        if (this.template.header.type === 'IMAGE') {
          this.uploadPicture();
        }

        if (this.template.header.type === 'DOCUMENT') {
          this.uploadFile();
        }

        if (this.template.header.type === 'TEXT') {
          this.sendRequest();
        }

        if (this.template.header.type === '') {
          this.sendRequest();
        }
      }
    },
    sendRequest() {
      WhatsAppService.createTextTemplate(this.template).then(
        (response) => {
          this.$root.$emit('template.submit');
          console.log(response);
          if (response.data.data.statusCode) {
            switch (response.data.data.statusCode) {
              case 400:
                this.$toast.show({
                  title: this.$t('template-component.error'),
                  content: this.$t('template-component.error-2'),
                  type: 'danger',
                });
                break;
              default:
            }
          } else {
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('template-component.created'),
              type: 'success',
            });
          }
          this.$router.go(-1);
          this.isSending = false;
        },
        (error) => {
          this.$toast.show({
            title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
            content: error.message,
            type: 'danger',
          });
          this.isSending = false;
        },
      );
    },
    checkParams() {
      const countVar = (this.template.body.match(/{{(.*?)}}/g) || []).length;
      this.template.body_examples = [];
      for (let i = 0; i < countVar; i += 1) {
        console.log(i);
        this.template.body_examples.push({
          index: i + 1,
          text: '',
        });
      }
    },
    removeCallTo(i) {
      this.template.call_to_actions.splice(i, 1);
    },
    removeReply(i) {
      this.template.buttons_reply.splice(i, 1);
    },
    addQuickReply() {
      if (this.template.buttons_reply.length < 3) {
        this.template.buttons_reply.push(
          { type: 'QUICK_REPLY', text: '' },
        );
      }
    },
    changeCategory(category) {
      this.template.category = category;
    },
    paramsChange(value) {
      this.template.params = value;
      this.checkParams();
    },
    addCallToButton() {
      if (this.template.call_to_actions.length < 2) {
        let btnAdd = {
          type: 'PHONE_NUMBER',
          text: '',
        };
        this.template.call_to_actions.forEach((btn) => {
          if (btn.type === 'PHONE_NUMBER') {
            btnAdd = {
              type: 'URL',
              text: '',
            };
          }
        });
        this.template.call_to_actions.push(btnAdd);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.rw-buttons-preview {
  border-color: #EBEDF0 #dfe0e4 #d0d1d5;
  border-width: 1px;
  margin-left: 0px;
  margin-right: 0px;
  padding: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: solid 1px #E0E1E1;
  border-radius: 10px;
  padding-top: 18px;
  padding-bottom: 18px;
}
.optional-template-input {
  display: block;
  float: left;
  font-size: 12px;
  background: #f4f4f4;
  padding: 5px;
  border-radius: 10px;
}
.btn i {
  line-height: 1;
  color: inherit;
}
.new-template-whats {
  padding: 15px;
  margin: 0 auto;
  background: #fff;
  margin: 10px;
}
.new-template-title {
  color: rgb(28, 30, 33);
}
.new-template-whats p span {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: normal;
  overflow-wrap: normal;
  text-align: left;
  color: rgb(96, 103, 112);
}
.input-info {
  position: absolute;
  right: 10px;
  top: 6px;
  color: #aaa;
}
.template-preview-new {
  background-color: #e5ddd5; box-sizing: border-box; height: 100%;position: relative;z-index: 0;
  padding: 15px;
}
.template-preview-new::before {
  background: url('/assets/img/chat-bg.png');
  background-size: auto;
  background-size: 366.5px 666px;
  content: '';
  height: 100%;
  left: 0;
  opacity: .06;
  position: absolute;
  top: 0;
  width: 100%;
}
.preview-messages-container {
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 12px;
  margin-top: 12px;
  background-color: #fff;
  border-radius: 7.5px;
  border-top-left-radius: 7.5px;
  border-top-left-radius: 0;
  box-shadow: 0 1px .5px rgba(0, 0, 0, .15);
  min-height: 20px;
  position: relative;
  word-wrap: break-word;
  width: 90%;
  padding: 3px;
  margin: 1px;
}
.preview-messages-container::after {
  background: url('/assets/img/chat_buble.png') 50% 50% no-repeat;
  background-size: auto;
  background-size: contain;
  content: '';
  height: 19px;
  left: -12px;
  position: absolute;
  top: 0;
  width: 12px;
}
.time-messages {
  bottom: 10px;
  color: rgba(0, 0, 0, .4);
  font-size: 11px;
  height: 15px;
  line-height: 15px;
  position: absolute;
  right: 7px;
}

.preview-image-header {
  background-image: url('/assets/img/image_preview_white.png');
  background-size: 80px 80px;
}
.preview-image-header {
  background-color: #ccd0d5;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 4px;
  box-sizing: border-box;
  height: 130px;
}
.preview-message-body {
  color: #262626;
  font-size: 13.6px;
  line-height: 19px;
  padding: 7px 7px 6px 9px;

  & > span {
    white-space: pre-wrap;
  }
}
.preview-message-header {
  color: rgba(0, 0, 0, .76);
  font-size: 15px;
  font-weight: bold;
  line-height: 19px;
  padding: 6px 7px 0 9px;
}
.preview-message-footer {
  color: rgba(0, 0, 0, .45);
  font-size: 13px;
  line-height: 17px;
  padding: 0 7px 8px 9px;
}
.preview-message-btns {
  border-top: 1px solid #dadde1;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  padding: 5px;
  color: #00a5f4;
  font-size: 14px;
  line-height: 20px;
}
.preview-message-btns i {
  color: #00a5f4;
}
.preview-message-btns span {
  display: block;
  margin-bottom: 8px;
}
.preview-action-buttons {
  display: flex;
  flex-wrap: wrap;
  max-width: 90%;
}
.preview-action-buttons .div-reply{
  z-index: 1024;
  align-items: center;
  background-color: #fff;
  border-radius: 7.5px;
  box-shadow: 0 1px .5px rgba(0, 0, 0, .15);
  box-sizing: border-box;
  flex-grow: 1;
  margin: 2px 0 0 2px;
  min-width: calc(50% - 2px);
  color: #00a5f4;
  font-size: 14px;
  height: 34px;
  line-height: 20px;
  padding: 0 16px;
  white-space: pre-wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 5px;
}
.preview-media-select {
  flex: 0 1 auto;
  order: 0;
  margin-right: 20px;
  align-self: auto;
  background-color: #fff;
  border: 1px solid #dadde1;
  border-radius: 10px;
  border-width: 2px;
  box-sizing: border-box;
  cursor: pointer;
  height: 160px;
  justify-content: center;
  padding: 16px 24px;
  position: relative;
  width: 100%;
}
.preview-media-select:hover {
  background-color: #ecf3ff;
  border: 1px solid #0E1421;
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
  border-width: 2px;
}
.preview-media-select-active {
  background-color: #ecf3ff;
  border: 1px solid #0E1421;
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
  border-width: 2px;
}
.preview-media-select label {
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: contain;
  height: 80px;
  width: 60%;
  display: block;
  margin: 0 auto;
}
.preview-media-select-image label {
  background-image: url('/assets/img/image_preview.png');
}
.preview-media-select-video label {
  background-image: url('/assets/img/video_preview.png');
}
.preview-media-select-document label {
  background-image: url('/assets/img/document_preview.png');
}
.preview-media-select span {
  width: 100%;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: normal;
  font-weight: bold;
  overflow-wrap: normal;
  text-align: center;
  display: block;
  margin-top: 8px;
}
.list-group-item:hover {
  background: #ccc;
  cursor: pointer;
}
.list-group-item-active {
  background: #ecebff;
  color: #000;
}
.list-group-item-active strong {
  color: #000;
}
#preview-image-header-pdf {
  width: 100%;
}
#preview-image-header-pdf .toolbar {
  display: none !important;
}
#preview-image-header-pdf #viewerContainer {
  top: 0px !important;
}
#btn-send {
  top: 0px !important;
}
</style>
